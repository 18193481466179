import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getAllEvaUsers } from 'services/userServices';
import Flex from 'components/common/Flex';
import { Form } from 'react-bootstrap';
import MultiSelectNoRef from 'components/common/MultiSelectNoRef';

const UserDropdownV2 = ({
  label,
  fieldName,
  handleChange,
  isMulti = true,
  placeholder = '',
  value,
  collapseValues = false,
  maxToShow = 4,
  disabled = false
}) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();

    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const handleValue = value => {
    if (typeof value === 'number') {
      return users?.find(x => x.value === value);
    } else if (typeof value === 'string') {
      const arr = value.split(',');
      return users?.filter(x => arr?.some(i => i.toString() == x.value));
    } else if (
      Array.isArray(value) &&
      value.length > 0 &&
      Number.isInteger(value[0])
    ) {
      return users?.filter(x => value?.some(i => i.toString() == x.value));
    }

    return value;
  };

  return (
    <>
      {label && (
        <Flex alignItems="center" justifyContent="between">
          <Form.Label>{label}</Form.Label>
        </Flex>
      )}
      <MultiSelectNoRef
        closeMenuOnSelect={false}
        isMulti={isMulti}
        options={users}
        placeholder={placeholder}
        collapseValues={collapseValues}
        maxToShow={maxToShow}
        disabled={disabled}
        onChange={selectedOptions => {
          handleChange(fieldName, selectedOptions);
        }}
        value={handleValue(value)}
      />
    </>
  );
};

UserDropdownV2.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number,
  disabled: PropTypes.bool
};

export default UserDropdownV2;
