import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function GrowSpinner(props) {
  let numberOfDots = props.quantity ?? 1;
  let variant = props.variant ?? 'primary';

  let res = [];

  for (let i = 0; i < numberOfDots; i++) {
    res.push(
      <Spinner key={i} animation="grow" variant={variant} size="sm"></Spinner>
    );
  }

  return <>{res}</>;
}
