import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import AppContext from 'context/Context';
import ProjectDashboard from 'components/pages/npd/projects/ProjectDashboard';
import ProjectManager from 'components/pages/npd/projects/ProjectManager';
import AuthError from 'components/errors/AuthError';
import ProtectedRoute from 'components/authenticated-routes/ProtectedRoute';
import Error404 from 'components/errors/Error404';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
import NCDashboard from 'components/pages/new-critical/dashboard/NCDashboard';
import Home from 'components/pages/Home';
import AreaList from 'components/pages/stock-location/areas/AreaList';
import AreaManager from 'components/pages/stock-location/areas/AreaManager';
import SiteList from 'components/pages/stock-location/sites/SiteList';
import SiteManager from 'components/pages/stock-location/sites/SiteManager';
import LocationList from 'components/pages/stock-location/locations/LocationList';
import LocationManager from 'components/pages/stock-location/locations/LocationManager';
import BinList from 'components/pages/stock-location/bins/BinList';
import BinManager from 'components/pages/stock-location/bins/BinManager';
import StockTransactionList from 'components/pages/stock-location/stock-transactions/StockTransactionList';
import StockList from 'components/pages/stock-location/stocks/StockList';
import StockSearch from 'components/pages/stock-location/stocks/StockSearch';
import ActionPlanManager from 'components/pages/customer-service/action-plans/create-action-plan/ActionPlanManager';
import ActionPlanDashboard from 'components/pages/customer-service/action-plans/action-plan-dashboard/ActionPlanDashboard';
import Calendar from 'components/pages/customer-service/meetings/calendar/Calendar';
import MeetingDetail from 'components/pages/customer-service/meetings/meeting-detail/MeetingDetail';

import AuthRoute from 'components/authenticated-routes/AuthRoute';
import Error403 from 'components/errors/Error403';
import PermissionSetupList from 'components/pages/permissions/permission-setup/PermissionSetupList';
import PermissionSetupManager from 'components/pages/permissions/permission-setup/PermissionSetupManager';
import UserPermissionList from 'components/pages/permissions/user-permission/UserPermissionList';
import UserPermissionManager from 'components/pages/permissions/user-permission/UserPermissionManager';
import SectionDashboard from 'components/pages/skill-matrix/sections/sections-dashboard/SectionDashboard';
import SkillCriteriaDashboard from 'components/pages/skill-matrix/skills/skill-criteria-dashboard/SkillCriteriaDashboard';// import SettingsToggle from 'components/settings-panel/SettingsToggle';
import BalancedScorecard from 'components/pages/npd/dashboards/balanced-scorecard/measurable-dashboard/BalancedScorecard';
const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        {/* {Auth errors} */}
        <Route element={<ErrorLayout />}>
          <Route path="errors/authError" element={<AuthError />} />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="author/403Error" element={<Error403 />} />
        </Route>

        {/* {MainLayout}  */}
        <Route path="/" element={<ProtectedRoute />}>
          {/* <Route> */}
          <Route element={<MainLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="npd/dashboard" element={<ProjectDashboard />} />
            <Route
              exact
              path="npd/project-manager"
              element={<ProjectManager />}
            />
            <Route
              exact
              path="new-critical/nc-dashboard"
              element={<NCDashboard />}
            />
            <Route
              exact
              path="customer-service/action-plan-board"
              element={<ActionPlanDashboard />}
            />
            <Route
              exact
              path="customer-service/action-plan-manager/:id"
              element={<ActionPlanManager />}
            />
            <Route
              exact
              path="customer-service/meetings/calendar"
              element={<Calendar />}
            />
            <Route
              exact
              path="customer-service/meetings/:id"
              element={<MeetingDetail />}
            />

            <Route
              exact
              path="hr/skill-matrix/section/section-dashboard"
              element={<SectionDashboard />}
            />
            <Route
              exact
              path="hr/skill-matrix/section/skills/criteria-dashboard"
              element={<SkillCriteriaDashboard />}
            />

            <Route
              exact
              path="admin/permission/permissions-setup/permission-setup-dashboard"
              element={
                <AuthRoute>
                  <PermissionSetupList />
                </AuthRoute>
              }
            />
            <Route
              exact
              path="admin/permission/permissions-setup/permission-setup-manager"
              element={
                <AuthRoute>
                  <PermissionSetupManager />
                </AuthRoute>
              }
            />
            <Route
              exact
              path="admin/permission/user-permission/user-permission-dashboard"
              element={
                <AuthRoute>
                  <UserPermissionList />
                </AuthRoute>
              }
            />
            <Route
              exact
              path="admin/permission/user-permission/user-permission-manager"
              element={
                <AuthRoute><UserPermissionManager />
                </AuthRoute>
              }
            />
            <Route
              path="/stock-location/area-management/areas"
              element={
                <AuthRoute><AreaList />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/area-management/area-manager"
              element={
                <AuthRoute><AreaManager />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/site-management/sites"
              element={
                <AuthRoute><SiteList />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/site-management/site-manager"
              element={
                <AuthRoute><SiteManager />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/bin-management/bins"
              element={
                <AuthRoute><BinList />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/bin-management/bin-manager"
              element={
                <AuthRoute>
                  <BinManager />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/location-management/locations"
              element={
                <AuthRoute>
                  <LocationList />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/location-management/location-manager"
              element={
                <AuthRoute>
                  <LocationManager />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/stock-management/stock-transaction"
              element={
                <AuthRoute>
                  <StockTransactionList />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/stock-management/stocks"
              element={
                <AuthRoute>
                  <StockList />
                </AuthRoute>
              }
            />
            <Route
              path="stock-location/stock-management/stock-search"
              element={
                <AuthRoute>
                  <StockSearch />
                </AuthRoute>
              }
            />
            <Route
              path="/kpi/balancedScorecard"
              element={<BalancedScorecard />}
            />
          </Route>
        </Route>

        {/* {Fallback <Navigate to="/errors/404" />} */}
        <Route path="*" element={<Error404 />} />
      </Routes>

      {/* {Customisation menu} */}
      {/* <SettingsToggle /> */}
      <SettingsPanel />
      {/* {toast container for whole app} */}
      <ToastContainer
        toastClassName="text-white"
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
      />
    </>
  );
};

export default Layout;
