import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import '../../../../../../assets/scss/user.scss';
import GrowSpinner from 'components/common/GrowSpinner';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { Badge, Table } from 'react-bootstrap';
import AppContext from 'context/Context';
import TargetSettingModal from './TargetSettingModal';
import MeasurableModal from './MeasurableModal';
import { search } from 'services/bs-sector-measurable/measurableService';
import { search as searchTarget } from 'services/bs-sector-measurable/targetService';
import { search as searchMeasureabledData } from 'services/bs-sector-measurable/measurableDataService';
import moment from 'moment';
import classNames from 'classnames';
import { buildTargetTextDisplay } from 'helpers/kpiHelper';

export default function SectorCard({ sector, selectedDate, noOfWeek = 4 }) {
  const {
    config: { isDark }
  } = useContext(AppContext);
  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);

  const [weeksAgo, setWeeksAgo] = useState({});
  const [selectedCurrentMonth, setSelectedCurrentMonth] = useState(
    dayjs(new Date()).startOf('month').toDate()
  );
  const [selectedSubOneMonth, setSelectedSubOneMonth] = useState(
    dayjs(new Date()).subtract(1, 'month').startOf('month').toDate()
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const selectedWeek = dayjs(selectedDate).week();

    setWeeksAgo({
      fourWeeksAgo: dayjs(selectedDate)
        .week(selectedWeek - 4)
        .startOf('week')
        .format('YYYY-MM-DD'),
      threeWeeksAgo: dayjs(selectedDate)
        .week(selectedWeek - 3)
        .startOf('week')
        .format('YYYY-MM-DD'),
      twoWeeksAgo: dayjs(selectedDate)
        .week(selectedWeek - 2)
        .startOf('week')
        .format('YYYY-MM-DD'),
      oneWeekAgo: dayjs(selectedDate)
        .week(selectedWeek - 1)
        .startOf('week')
        .format('YYYY-MM-DD')
    });

    setSelectedCurrentMonth(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
    );

    setSelectedSubOneMonth(
      dayjs(selectedDate).subtract(1, 'month').startOf('month').toDate()
    );
  }, [selectedDate]);

  const defaultInitialObj = {
    sectorId: sector.id
  };

  useEffect(() => {
    setMesurableData({ sectorId: sector.id });
    getMeasurables();
  }, [sector]);

  useEffect(() => {
    getMeasurables();
  }, [selectedCurrentMonth, selectedSubOneMonth]);

  const [measurables, setMeasurables] = useState([]);
  // const getOptionList()
  const getMeasurables = async () => {
    setIsLoading(true);
    let resMeasurables = await search({ isGetAll: true, sectorId: sector.id });
    let resTargets = await searchTarget({
      isGetAll: true,
      sectorId: sector.id
    });
    let mesuarableValuesRs = await searchMeasureabledData({
      isGetAll: true,
      sectorId: sector.id
    });
    let measurables = resMeasurables.items.map(x => {
      let currentMonthTarget = resTargets.items.find(
        t =>
          t.measurableId == x.id &&
          new Date(t.date).getTime() === selectedCurrentMonth.getTime()
      );

      let previousMonthTarget = resTargets.items.find(
        t =>
          t.measurableId == x.id &&
          new Date(t.date).getTime() === selectedSubOneMonth.getTime()
      );

      let mesuarableValues = mesuarableValuesRs?.items?.filter(
        mv => mv.measurableId == x.id
      );
      let rs = {
        ...x,
        dateData: mesuarableValues,
        fourWeeksAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.fourWeeksAgo
        ),
        threeWeeksAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.threeWeeksAgo
        ),
        twoWeeksAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.twoWeeksAgo
        ),
        oneWeekAgo: mesuarableValues.find(
          mv => dayjs(mv.date).format('YYYY-MM-DD') == weeksAgo.oneWeekAgo
        ),
        currentMonthTarget: currentMonthTarget,
        previousMonthTarget: previousMonthTarget
      };

      return rs;
    });
    setMeasurables(measurables);
    setIsLoading(false);
  };

  const [isMesurableShow, setIsMesurableShow] = useState(false);
  const [mesurableData, setMesurableData] = useState({});

  const toggleMesurabledModal = () => {
    setIsMesurableShow(!isMesurableShow);
  };

  const handleCloseMesurableModal = () => {
    setIsMesurableShow(false);
    setMesurableData({ ...defaultInitialObj });
  };

  const handleSubmitMesurableModal = () => {
    handleCloseMesurableModal();
    getMeasurables();
  };
  //
  const [isTargetSettingShow, setIsTargetSettingShow] = useState(false);
  const [targetSettingData, setTargetSettingData] = useState({});

  const toggleTargetSettingdModal = () => {
    setIsTargetSettingShow(!isTargetSettingShow);
  };

  const handleCloseTargetSettingModal = () => {
    setIsTargetSettingShow(false);
    setTargetSettingData({});
  };

  const handleSubmitTargetSettingModal = () => {
    handleCloseTargetSettingModal();
    getMeasurables();
  };

  const checkColor = (
    variationPercentage,
    data,
    currentMonthTarget,
    previousMonthTarget
  ) => {
    if (!data) return;

    let dateStr = dayjs(data?.date).format('YYYY-MM');
    let compareMonthTarget =
      dateStr == dayjs(currentMonthTarget?.date).format('YYYY-MM')
        ? currentMonthTarget
        : previousMonthTarget;

    if (variationPercentage == 0 || variationPercentage == undefined) {
      if (data.value == compareMonthTarget.target) {
        return 'kpiWarning';
      } else if (parseInt(data.value) > parseInt(compareMonthTarget.target)) {
        return 'kpiSafe';
      } else {
        return 'kpiDanger';
      }
    } else {
      let percent =
        (1 - parseInt(data?.value) / parseInt(compareMonthTarget.target)) * 100;
      if (data.value == 51)
        console.log(
          variationPercentage,
          data,
          currentMonthTarget,
          previousMonthTarget,
          compareMonthTarget,
          percent
        );

      if (percent > 0) {
        if (percent > variationPercentage) {
          return 'kpiDanger';
        } else {
          return 'kpiWarning';
        }
      } else {
        return 'kpiSafe';
      }
    }
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h3>{sector.name}</h3>
          <Button variant="outline-primary" onClick={toggleMesurabledModal}>
            Create
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12}>
              {isLoading ? (
                <GrowSpinner quantity={3}></GrowSpinner>
              ) : (
                <Table
                  responsive
                  borderless={true}
                  style={{
                    backgroundColor: !isDark ? '#edf2f9' : '#0B1727',
                    borderRadius: '10px'
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: '5vw' }}>#</th>
                      <th style={{ width: '20vw' }}>Measurable</th>
                      <th className="text-center" style={{ width: '15vw' }}>
                        Target {moment(selectedSubOneMonth).format('MMM-YY')}
                      </th>
                      <th className="text-center" style={{ width: '15vw' }}>
                        Target {moment(selectedCurrentMonth).format('MMM-YY')}
                      </th>
                      <th className="text-center" style={{ width: '10vw' }}>
                        Variation Percentage
                      </th>
                      <th
                        className="text-center"
                        style={{ width: '15vw' }}
                        hidden={noOfWeek < 4}
                      >
                        <Badge style={{ fontSize: '12px' }}>
                          {dayjs(weeksAgo.fourWeeksAgo).format('DD-MMM')}
                        </Badge>
                        <br></br>Week -4
                      </th>
                      <th
                        className="text-center"
                        style={{ width: '15vw' }}
                        hidden={noOfWeek < 3}
                      >
                        <Badge style={{ fontSize: '12px' }}>
                          {dayjs(weeksAgo.threeWeeksAgo).format('DD-MMM')}
                        </Badge>
                        <br></br>Week -3
                      </th>
                      <th
                        className="text-center"
                        style={{ width: '15vw' }}
                        hidden={noOfWeek < 2}
                      >
                        <Badge style={{ fontSize: '12px' }}>
                          {dayjs(weeksAgo.twoWeeksAgo).format('DD-MMM')}
                        </Badge>
                        <br></br>Week -2
                      </th>
                      <th className="text-center" style={{ width: '15vw' }}>
                        <Badge style={{ fontSize: '12px' }}>
                          {dayjs(weeksAgo.oneWeekAgo).format('DD-MMM')}
                        </Badge>
                        <br></br>Week -1
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {measurables?.map((x, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <i>
                              <b>{index + 1}</b>
                            </i>
                          </td>
                          <td
                            onClick={() => {
                              toggleMesurabledModal();
                              setMesurableData(x);
                            }}
                          >
                            <i>
                              <b>{x.name}</b>
                            </i>
                          </td>
                          <td
                            className="text-center "
                            onClick={() => {
                              toggleTargetSettingdModal();
                              setTargetSettingData({
                                ...x,
                                date: selectedSubOneMonth,
                                target: x.previousMonthTarget?.target
                              });
                            }}
                          >
                            {buildTargetTextDisplay(
                              x.targetTypeValue,
                              x.previousMonthTarget?.target?.toLocaleString(
                                'en-US'
                              )
                            )}
                            {/* {x.targetTypeValue} {' '}
                            {x.previousMonthTarget?.target?.toLocaleString(
                              'en-US'
                            )} */}
                          </td>
                          <td
                            className="text-center "
                            onClick={() => {
                              toggleTargetSettingdModal();
                              setTargetSettingData({
                                ...x,
                                date: selectedCurrentMonth,
                                target: x.currentMonthTarget?.target
                              });
                            }}
                          >
                            {buildTargetTextDisplay(
                              x.targetTypeValue,
                              x.currentMonthTarget?.target?.toLocaleString(
                                'en-US'
                              )
                            )}
                          </td>
                          <td className="text-center ">
                            {x.variationPercentage}%
                          </td>
                          <td
                            className={classNames(
                              'text-center',
                              checkColor(
                                x.variationPercentage,
                                x.fourWeeksAgo,
                                x.currentMonthTarget,
                                x.previousMonthTarget
                              )
                            )}
                          >
                            {buildTargetTextDisplay(
                              x.targetTypeValue,
                              x.fourWeeksAgo?.value?.toLocaleString(
                                'en-US'
                              )
                            )}

                            {/* {x.targetTypeValue} {x.fourWeeksAgo?.value} */}
                          </td>
                          <td
                            className={classNames(
                              'text-center',
                              checkColor(
                                x.variationPercentage,
                                x.threeWeeksAgo,
                                x.currentMonthTarget,
                                x.previousMonthTarget
                              )
                            )}
                            hidden={noOfWeek < 3}
                          >
                            {buildTargetTextDisplay(
                              x.targetTypeValue,
                              x.threeWeeksAgo?.value?.toLocaleString('en-US')
                            )}
                          </td>
                          <td
                            className={classNames(
                              'text-center',
                              checkColor(
                                x.variationPercentage,
                                x.twoWeeksAgo,
                                x.currentMonthTarget,
                                x.previousMonthTarget
                              )
                            )}
                            hidden={noOfWeek < 2}
                          >
                            {buildTargetTextDisplay(
                              x.targetTypeValue,
                              x.twoWeeksAgo?.value?.toLocaleString('en-US')
                            )}
                          </td>
                          <td
                            className={classNames(
                              'text-center',
                              checkColor(
                                x.variationPercentage,
                                x.oneWeekAgo,
                                x.currentMonthTarget,
                                x.previousMonthTarget
                              )
                            )}
                          >
                            {buildTargetTextDisplay(
                              x.targetTypeValue,
                              x.oneWeekAgo?.value?.toLocaleString('en-US')
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isMesurableShow && (
        <MeasurableModal
          isShow={isMesurableShow}
          handleClose={handleCloseMesurableModal}
          data={mesurableData}
          handleSubmit={handleSubmitMesurableModal}
        />
      )}
      {isTargetSettingShow && (
        <TargetSettingModal
          isShow={isTargetSettingShow}
          handleClose={handleCloseTargetSettingModal}
          data={targetSettingData}
          handleSubmit={handleSubmitTargetSettingModal}
        />
      )}
    </>
  );
}
