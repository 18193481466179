const toGBP = value => {
  return value.toLocaleString('en-UK', {
    style: 'currency',
    currency: 'GBP'
  });
};
export { toGBP, buildTargetTextDisplay };

const buildTargetTextDisplay = (typeValue, data) => {
 
  if (!data) {
    return typeValue;
  }
  if (!typeValue) {
    return data;
  }
  if (typeValue == '£') {
    return typeValue + ' ' + data;
  }
  if(typeValue == '%') {
    console.log(typeValue, data, 'aaa')
  }
  return data + ' ' + typeValue;
};
