import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
const InputDatePicker = ({ column, displayName, selected, handleChange }) => {
  useEffect(() => {
    setTempDate(selected? selected.toLocaleDateString('en-GB') : '');
  }, [selected]);

  const [tempDate, setTempDate] = useState('');
  const handleDateChange = e => {
    const value = e.target.value;
    if(value == ''){
      handleChange(column, '');
      return;
    }
    console.log(value)
    setTempDate(value);
    const parts = value.split('/');
    if (
      parts.length === 3 &&
      parts[0].length === 2 &&
      parts[1].length === 2 &&
      parts[2].length === 4
    ) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      const newDate = new Date(Date.UTC(year, month - 1, day));
      if (
        newDate.getFullYear() === year &&
        newDate.getMonth() === month - 1 &&
        newDate.getDate() === day
      ) {
        handleChange(column, newDate);
      }
    }
  };

  return (
    <Form.Group controlId={column}>
      <Form.Label>{displayName}</Form.Label>
      <DatePicker
        selected={selected}
        onChange={newDate => {
          const newDateUTC = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
          handleChange(column, newDateUTC);
          setTempDate(newDate ? newDateUTC.toLocaleDateString() : '');
        }}
        customInput={
          <CustomDateInput
            formControlProps={{
              placeholder: 'dd/mm/yyyy',
              value: tempDate,
              onChange: handleDateChange
            }}
          />
        }
      />
    </Form.Group>
  );
};

InputDatePicker.propTypes = {
  column: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]),
  handleChange: PropTypes.func,
};

export default InputDatePicker;
