import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import { post, patch } from 'services/bs-sector-measurable/measurableService';
import OptionListSelect from 'components/Dropdowns/OptionListSelect';
const MeasurableModal = ({ data, isShow, handleClose, handleSubmit }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  useEffect(() => {
    setFormData(data);
  }, [data]);

  const [formData, setFormData] = useState(data);
  const handleChange = ({ target }) => {
    console.log(target);
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    console.log('submit');
    e.preventDefault();
    console.log(formData);

    if (formData.id > 0) {
      await patch(formData.id, { ...formData });
    } else {
      await post({ ...formData });
    }
    handleSubmit();
  };

  useEffect(() => {}, []);

  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">
            {formData.id > 0 ? 'Edit' : 'Create'} Measurable
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData?.name}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="targetCurrent">
            <Form.Label className="fs-0">Target Current Month</Form.Label>
            <Form.Control
              type="text"
              name="targetCurrent"
              required
              value={formData?.targetCurrent}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="targetPrevious">
            <Form.Label className="fs-0">Target Previous Month</Form.Label>
            <Form.Control
              type="text"
              name="targetPrevious"
              required
              value={formData?.targetPrevious}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="targetType">
            <Form.Label className="fs-0">Target Type</Form.Label>
            <OptionListSelect
              optionKey="balance.score.card.targettype"
              selected={formData && formData.targetType ? formData.targetType : 0}
              changeFn={(opt) => handleChange({ target: { name: 'targetType', value: opt.value } })} //opt => singleSelectChange(opt, 'priority')}
            />
            {/* <Form.Control
              type="text"
              name="targetType"
              required
              value={formData?.targetType}
              onChange={handleChange}
            /> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="variationPercentage">
            <Form.Label className="fs-0">
              Target Variation Percentage
            </Form.Label>
            <Form.Control
              type="number"
              name="variationPercentage"
              required
              value={formData?.variationPercentage}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

MeasurableModal.propTypes = {
  id: PropTypes.number,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  name: PropTypes.string
};

export default MeasurableModal;
